.page_11{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_11/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top:863px;
    left: 907px;
	}
}
