.score {
	font-family: "Homer Simpson Revised", sans-serif;
  background: url("/img/public/UI-score.png");
  width: 380px;
  height: 148px;
  position: absolute;
  top: -15px;
  left: 1540px;
  color: #fff;
  font-size: 48px;
  text-transform: uppercase;
  text-shadow: none;
  padding: 0px;
	z-index:500;
  p {
    position: absolute;
    left: 70px;
		width: 265px;
    display: flex;
    justify-content: space-between;
    span {
      color: #40e001;
    }
  }
  
  transition: opacity 250ms ease-in-out;
	
	&.hidden{
		opacity:0;
	}
}
