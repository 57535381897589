.badges {
	position: absolute;
	top: 26px;
	left: 1084px;
	gap:25px;
	
	z-index:500;
	
	display: flex;
	
	img{
		opacity:0;
		transition: opacity 500ms ease-in-out;
	}
	
	&.badges1{
		img:nth-child(1){opacity:1}
	}
	&.badges2{
		img:nth-child(1){opacity:1}
		img:nth-child(2){opacity:1}
	}
	&.badges3{
		img:nth-child(1){opacity:1}
		img:nth-child(2){opacity:1}
		img:nth-child(3){opacity:1}
	}
	&.badges4{
		img:nth-child(1){opacity:1}
		img:nth-child(2){opacity:1}
		img:nth-child(3){opacity:1}
		img:nth-child(4){opacity:1}
	}
	&.badges5{
		img:nth-child(1){opacity:1}
		img:nth-child(2){opacity:1}
		img:nth-child(3){opacity:1}
		img:nth-child(4){opacity:1}
		img:nth-child(5){opacity:1}
	}
}
