.page_3{
  width: 1920px;
  height: 1080px;
  background: url("/img/page_3/bkg.jpg")  center center no-repeat;
	
	position: relative;

	.hand_up {
		position: absolute;
		top: 710px;
		left: 530px;
	}

	.word {
		position: absolute;
	}
}
