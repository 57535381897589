.page_27{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_27/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.arrow_up{
		top: 835px;
		left: 335px;
	}
	
	.touch{
		position: absolute;
		top: 760px;
		left: 328px;
		width: 100px;
		height: 100px;
	}
}
