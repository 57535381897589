.begin-button {
  font-family: "Galaxie Polaris Condensed Book", sans-serif;
  position: absolute;
  top: 890px;
  left: 1220px;
  width: 514px;
  height: 145px;
  letter-spacing: 1px;

  font-size: 48px;
  color: rgb(71, 71, 71);
  border: none;
  background: url("/img/public/UI-button-small.png") content-box no-repeat
    center;
  padding: 0px;
			
	transition: opacity 500ms ease-in-out;
  p {
    margin: 0;
    margin-bottom: 20px;
  }

  &:active {
    transform: scale(0.95);
  }
  
	&.hidden{
		opacity:0;
		pointer-events: none;
	}
}

.middle-button {
  position: absolute;
  top: 870px;
  left: 700px;
  width: 514px;
  height: 145px;
  letter-spacing: 1px;

  font-size: 48px;
  color: rgb(71, 71, 71);
  border: none;
  background: url("/img/public/UI-button-small.png") content-box no-repeat
    center;
  padding: 0px;
  p {
    margin: 0;
    margin-bottom: 20px;
  }

  &:active {
    transform: scale(0.95);
  }
}
