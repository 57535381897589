.page_4{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_4/bkg.jpg")  center center no-repeat;
	
	position: relative;

	.label{
		position: absolute;
		pointer-events: none;
		top: 133px;
		left: 184px;
	}
	
	.species {
		position: absolute;
		
		&.invasive.clicked{
			animation: scale-out-center 0.5s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
		}
		
		&.native.clicked{
			animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		}
		
		&[src="/img/page_4/american_bullfrog.png"]{
			top: 812px;
			left: 653px;
		}
		&[src="/img/page_4/bunchberry_dogwood.png"]{
			top: 557px;
			left: 1304px;
			z-index: 2;
		}
		&[src="/img/page_4/douglas_fir.png"]{
			top: 0px;
			left: 152px;
		}
		&[src="/img/page_4/eastern_cottontail.png"]{
			top: 391px;
			left: 990px;
		}
		&[src="/img/page_4/european_rabbit.png"]{
			top: 543px;
			left: 768px;
		}
		&[src="/img/page_4/european_wall_lizard.png"]{
			top: 658px;
			left: 1477px;
		}
		&[src="/img/page_4/periwinkle.png"]{
			top: 363px;
			left: 599px;
		}
		&[src="/img/page_4/scotch_broom.png"]{
			top: 534px;
			left: 328px;
		}
		&[src="/img/page_4/spongy_moth.png"]{
			top: 459px;
			left: 90px;
		}
	}
	
	.label_native{
		position: absolute;
		pointer-events: none;
		
		opacity:0;
		transition: opacity 250ms ease-in-out;
		
		&[data-for="bunchberry_dogwood"]{
			top: 514px;
			left: 1305px;
		}
		&[data-for="douglas_fir"]{
			top: 355px;
			left: 62px;
		}
		
		&.shown{
			opacity:1;
		}
	}
}
