.page_28{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_28/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
}
