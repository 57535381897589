.progress {
	font-family: "Homer Simpson Revised", sans-serif;
	background: url("/img/public/UI-progress-bar-back.png") no-repeat
		border-box;
	height: 80px;
	width: 600px;
	color: #fff;
	position: absolute;
	top: 30px;
	left: 450px;
	z-index:500;
	p {
		font-size: 48px;
		position: absolute;
		left: -240px;
		top: -40px;
    text-shadow: 0px 0px 10px #000;
	}
	span {
		background: url("/img/public/UI-progress-bar-green.png") left center no-repeat;
		position: absolute;
		top: 13px;
		left: 13px;
		width: 572px;
		height: 36px;
		transition: width 250ms ease-in-out;
	}
	
	transition: opacity 250ms ease-in-out;
	
	&.hidden{
		opacity:0;
	}
	
	&.progress0{
		span{
			width:0px;
		}
	}
	
	&.progress1{
		span{
			width:116px;
		}
	}
	
	&.progress2{
		span{
			width:229px;
		}
	}
	
	&.progress3{
		span{
			width:340px;
		}
	}
	
	&.progress4{
		span{
			width:449px;
		}
	}
	
	&.progress5{
		span{
			width:572px;
		}
	}
}
