.page_29{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_29/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
		
		&.begin{
			top: 863px;
			left: 785px;
		}
	}
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.note{
		position: absolute;
    top: 408px;
    left: 846px;
	
		pointer-events: none;
		
		transition: opacity 500ms ease-in-out;
		&.hidden{
			opacity:0;
		}
	}
	
	.water{
		position: absolute;
		
		opacity:1;
		
		transition: opacity 250ms ease-in-out;
		&.hidden{
			opacity:0;
			pointer-events: none;
		}
		
		&.water_0{
			top: 557px;
			left: 675px;
		}
		
		&.water_1{
			top: 550px;
			left: 840px;
		}
		
		&.water_2{
			top: 464px;
			left: 943px;
			padding:30px;
			margin: -30px;
		}
		
		&.water_3{
			top: 615px;
			left: 1179px;
		}
		
		&.water_4{
			top: 718px;
			left: 1280px;
		}
		
		&.water_5{
			top: 600px;
			left: 1530px;
		}
	}
	
	.cta{
		position: absolute;
		
		left: 1609px;
		bottom: 784px;
		
		width:154px;
		height:63px;
		background: url("/img/page_29/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
	}
}
