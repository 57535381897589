.page_18{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_18/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
	
	.cta{
		position: absolute;
		
    left: 1309px;
    bottom: 721px;
		
		width:490px;
		height:80px;
		background: url("/img/page_17/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
	}
}
