body {
  width: 100vw;
  height: 100vh;
  background: url("/img/background-img.png") content-box no-repeat;
  margin: 0;
  padding: 0;
  position: relative;
	overflow: hidden;
	
	user-select: none;
	-webkit-user-drag: none;

  .main-heading {
    font-size: 3.44rem;
    font-family: sans-serif;
    letter-spacing: 0.0625rem;
    color: rgb(255, 255, 255);
    font-weight: 300;
    line-height: 1.2;
    width: fit-content;
    position: absolute;
    top: 46rem;
    left: 6.375rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid white;
  }

  .underline-text {
    color: #fff;
    position: absolute;
    top: 58.3125rem;
    left: 6.375rem;
    font-family: sans-serif;
  }

  .logo {
    position: absolute;
    left: 99.5625rem;
    top: 58.3125rem;
  }
}

*{
	user-select: none;
	user-drag: none;
	-webkit-user-drag: none;
	-moz-user-drag: none;
	touch-action: none;
}

@font-face {
  font-family: "Homer Simpson Revised";
  src: url("/fonts/Homer_Simpson_Revised.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Galaxie Polaris Condensed Book";
  src: url("/fonts/GalaxiePolarisCondensed-Book.otf") format("truetype");
  font-display: swap;
}


//FONTS
.homer-font {
  font-family: "Homer Simpson Revised", sans-serif;
}

.galaxy-font {
  font-family: "Galaxie Polaris Condensed Book", sans-serif;
}

@import './button.scss';
@import "./keyframes.scss";

.clue-wrapper {
  font-family: "Galaxie Polaris Condensed Book", sans-serif;
	position: absolute;
	top: 730px;
	left: 964px;
	width: 743px;
	height: 175px;
	box-sizing: border-box;
	border: 2px solid black;
	padding:0px 50px;
	
	letter-spacing: 1px;
	font-size: 40px;
	color: #000;
		
	transition: opacity 500ms ease-in-out;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 25px;
	
  text-shadow: none;
	
	&.without-background{
		font-family: "Homer Simpson Revised", sans-serif;
    background: none;
    border: 0px;
		letter-spacing: 1px;
		font-size: 64px;
		color: #fff;
		text-align: center;
		text-shadow: 0px 0px 10px #000;
	}
	
	&.hidden{
		opacity: 0;
		pointer-events: none;
	}
	
	display: flex;
	justify-content: center;
	align-items: center;
}

.continue{
	position: absolute;
	top: 848px;
	left: 1312px;
	
	transition: opacity 500ms ease-in-out, transform 150ms ease-in-out;
	
	&.hidden{
		opacity:0;
		pointer-events: none;
	}
	
	&:active{
		transform: scale(0.965);
	}
	
	&.claim{
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
	
	&.replay{
		top: 883px;
		left: 1271px;
	}
}

.popup {
	position: absolute;
	
	transition: opacity 500ms ease-in-out;
	top: 825px;
	left: 1151px;
	
	&.hidden{
		opacity:0;
		pointer-events: none;
	}
	
	&.claim{
		top: calc(843px);
		left: calc(1088px);
	}
}

.arrow_up {
	position: absolute;
	top: 923px;
	left: 440px;
	
	animation: shake-vertical 3s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	
	transition: opacity 250ms ease-in-out;
	
	&.hidden{
		opacity: 0;
	}
}

.arrow_right {
	position: absolute;
	top: 611px;
	left: 1470px;
	
	animation: arrow_right 3s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.checkbox{
	position: absolute;
	top: 830px;
	left: 449px;
	
	transition: opacity 250ms ease-in-out;
	
	padding:50px;
	margin: -50px;
	
	&.hidden{
		opacity:0;
	}
}

.psl_pages{
	transform-origin:0px 0px;
}

.psl_page{
	position: absolute !important;
	left:0px;
	top:0px;
	transition: opacity 500ms ease-in-out;
	z-index:1;
}

.psl_ui_loader{
	display: none;
}
