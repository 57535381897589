.page_31{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_31/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.total_score{
    position: absolute;
    top: 268px;
    left: 1659px;
    font-size: 46px;
    text-transform: uppercase;
    font-weight: 500;
    width: 140px;
    color: #3d9f0d;
    font-family: "Homer Simpson Revised", sans-serif;
	}
	
	.score_label{
		position: absolute;
		top: 339px;
		left: 1366px;
		font-size: 44px;
		font-weight: 500;
		width: 459px;
		color: #733f12;
		font-family: "Homer Simpson Revised", sans-serif;
		font-style: italic;
		letter-spacing: 0.3px;
		line-height: 54px;
	}
	
	&.links_disabled{
		.area{
			pointer-events: none;
			cursor: default;
		}
	}
	
	.area{
		position: absolute;
		cursor: pointer;
		
		&[href="https://bcinvasives.ca/play-your-part/clean-drain-dry/"]{
			top: 665px;
			left: 308px;
			width: 200px;
			height: 100px;
		}
		
		&[href="https://bcinvasives.ca/play-your-part/plantwise/"]{
			top: 730px;
			left: 520px;
			width: 118px;
			height: 180px;
		}
		
		&[href="https://bcinvasives.ca/play-your-part/buy-local-burn-local/"]{
			top: 785px;
			left: 308px;
			width: 200px;
			height: 120px;
		}
		
		&[href="https://bcinvasives.ca/play-your-part/dont-let-it-loose/"]{
			top: 630px;
			left: 643px;
			width: 220px;
			height: 145px;
		}
		
		&[href="https://bcinvasives.ca/play-your-part/play-clean-go/"]{
			top: 783px;
			left: 680px;
			width: 130px;
			height: 120px;
		}
	}
}
