@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}


@keyframes shake-vertical {
	0%{
		transform: translateY(0px);
	}
	40%{
		transform: translateY(0px);
	}
	50%{
		transform: translateY(-10px);
	}
	60%{
		transform: translateY(0px);
	}
	70%{
		transform: translateY(-10px);
	}
	80%{
		transform: translateY(0px);
	}
	90%{
		transform: translateY(-10px);
	}
	100%{
		transform: translateY(0px);
	}
}

@keyframes arrow_right {
	0%{
		transform: translateX(0px);
	}
	40%{
		transform: translateX(0px);
	}
	50%{
		transform: translateX(10px);
	}
	60%{
		transform: translateX(0px);
	}
	70%{
		transform: translateX(10px);
	}
	80%{
		transform: translateX(0px);
	}
	90%{
		transform: translateX(10px);
	}
	100%{
		transform: translateY(0px);
	}
}
