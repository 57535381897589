.page_23{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_23/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
		
		&.begin{
			top: 861px;
			left: 777px;
		}
    z-index: 2;
	}
	
	.note{
		position: absolute;
    top: 181px;
    left: 193px;
	
		transition: opacity 500ms ease-in-out;
		&.hidden{
			opacity:0;
			pointer-events: none;
		}
	}
	
	.popup{
		top: 843px;
		left: 1088px;
    z-index: 2;
		pointer-events: none;
	}
	
	.timer {
		font-family: "Homer Simpson Revised", sans-serif;
		position: absolute;
		left: 68px;
		top: 964px;
		color: #fff;
		font-size: 48px;
		text-shadow: 0px 0px 10px black;
		letter-spacing: -1px;
		span {
			position: absolute;
			font-size: 72px;
			top: -17px;
			left: 125px;
		}
	
		transition: opacity 500ms ease-in-out;
		&.hidden{
			opacity:0;
			pointer-events: none;
		}
	}
	
	.species{
		position: absolute;
	}
	
	.cta_container{
    position: absolute;
    left: 1275px;
    bottom: 618px;
    z-index: 2;
		pointer-events: none;
	}
	
	.cta{
		position: absolute;
		
		left: 1388px;
		bottom: 706px;
		
		width:398px;
		height:0px;
		background: url("/img/page_23/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
    z-index: 3;
	}
}
