.page_5{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_5/bkg.jpg")  center center no-repeat;
	
	position: relative;
	
	.popup2{
		left: 745px;
		top: 350px;
	}
	
	.species {
		position: absolute;
		
		&.invasive.clicked{
			animation: scale-out-center 0.5s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
		}
		
		&.native.clicked{
			animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		}
		
		&[src="/img/page_5/eurasian_watermilfoil.png"]{
			top: 688px;
			left: 544px;
		}
		&[src="/img/page_5/goldfish.png"]{
			top: 635px;
			left: 963px;
		}
		&[src="/img/page_5/northern_pandweed.png"]{
			top: 348px;
			left: 813px;
		}
		&[src="/img/page_5/rainbow_trout.png"]{
			top: 510px;
			left: 241px;
		}
		&[src="/img/page_5/red_eared_slider.png"]{
			top: 125px;
			left: 52px;
		}
		&[src="/img/page_5/western_painted_turtle.png"]{
			top: 385px;
			left: 1536px;
		}
		&[src="/img/page_5/zebra_mussels.png"]{
			top: 853px;
			left: 158px;
		}
	}
	
	.label_native{
		position: absolute;
		pointer-events: none;
		
		opacity:0;
		transition: opacity 250ms ease-in-out;
		
		&[data-for="northern_pandweed"]{
			top: 329px;
			left: 790px;
		}
		&[data-for="rainbow_trout"]{
			top: 476px;
			left: 236px;
		}
		&[data-for="western_painted_turtle"]{
			top: 335px;
			left: 1536px;
		}
		
		&.shown{
			opacity:1;
		}
	}
}
