.page_6{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_6/bkg.jpg")  center center no-repeat;
	
	position: relative;
	
	.activity {
		position: absolute;
		
		padding:40px 60px;
		margin: -40px -60px;
		
		&.activity_1{
			top: 143px;
			left: 479px;
		}
		&.activity_2{
			top: 151px;
			left: 1012px;
		}
		&.activity_3{
			top: 514px;
			left: 1586px;
			
		}
		&.activity_4{
			top: 591px;
			left: 585px;
		}
		&.activity_5{
			top: 836px;
			left: 615px;
		}
	}
}
