.audio{
	position: absolute;
	top: 25px;
	left: 35px;
	z-index:500;
	width:112px;
	height:71px;
	*{
		position: absolute;
		top:0px;
		left:0px;
		transition: opacity 150ms ease-in-out;
	}
	&.on .on{
		opacity:1;
	}
	&.off .on{
		opacity:0;
	}
	
	&.disabled{
		display: none;
	}
}
