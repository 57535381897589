.page_9{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_9/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.timer {
		font-family: "Homer Simpson Revised", sans-serif;
		position: absolute;
		left: 196px;
		top: 964px;
		color: #fff;
		font-size: 48px;
		text-shadow: 0px 0px 10px black;
		letter-spacing: -1px;
		span {
			position: absolute;
			font-size: 72px;
			top: -17px;
			left: 125px;
		}
	}
	
	.species{
		position: absolute;
	}
	
	.cta{
		position: absolute;
		
    left: 1163px;
    bottom: 783px;
		
		width:601px;
		height:0px;
		background: url("/img/page_9/cta.png") center bottom no-repeat;
		background-size:601px auto;
		
		transition: height 150ms ease-in-out;
	}
}
