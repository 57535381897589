.page_19{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_19/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.magnifier_container{
		position: absolute;
		transform: translate3d(-50%,-50%,0px);
		
		.magnifier{
			position: relative;
			z-index: 2;
		}
		
		.hidder{
			position: absolute;
			top: 19px;
			left: 19px;
			right: 19px;
			bottom: 19px;
			overflow: hidden;
			z-index: 1;
			border-radius: 50%;
		}
	}
	
	.cta{
		position: absolute;
		
		left: 1266px;
		bottom: 683px;
		
		width:490px;
		height:155px;
		background: url("/img/page_17/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
	}
	
	.counter{
		position: absolute;
		top: 946px;
		left: 542px;
		color: #49ea03;
		font-size: 73px;
		width: 120px;
		text-align: right;
		font-family: "Homer Simpson Revised";
		letter-spacing: -2px;
		text-shadow: 0px 0px 10px black;
	}
}
