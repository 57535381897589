.page_1 {
  width: 1920px;
  height: 1080px;
  background: url("/img/page_1/bkg.jpg")  center center no-repeat;
	
	position: relative;
	
	overflow:hidden;

	.species {
		position: absolute;
		
		top:455px;
		display: flex;
	}
	
	.cta {
		position: absolute;
    top: 887px;
    left: 685px;
	
		transition: transform 150ms ease-in-out;
		
		&:active{
			transform: scale(0.965);
		}
	}
}
