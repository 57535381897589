.page_2 {
  width: 1920px;
  height: 1080px;
  background: url("/img/page_2/bkg.jpg")  center center no-repeat;
	
	position: relative;
	.hand_up {
		position: absolute;
		top: 701px;
		left: 700px;
	}

	.word {
		position: absolute;
	}
}
