.page_14{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_14/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
	}
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.figure{
		position: absolute;
	}
	
	.figure_label{
		position: absolute;
		
		transition: opacity 250ms ease-in-out;
		&.hidden{
			opacity:0;
		}
		
		&.figure_label_0{
			top: 514px;
			left: 91px;
		}
		&.figure_label_1{
			top: 514px;
			left: 322px;
		}
		&.figure_label_2{
			top: 792px;
			left: 101px;
		}
		&.figure_label_3{
			top: 792px;
			left: 299px;
		}
	}
	
	.label{
		position: absolute;
    top: 368px;
    left: 540px;
		transition: opacity 250ms ease-in-out;
		&.hidden{
			opacity:0;
		}
	}
	
	.hand_right {
		position: absolute;
    top: 724px;
    left: 559px;
	}
	
	.cta{
		position: absolute;
		
    left: 1354px;
    bottom: 811px;
		
		width:439px;
		height:34px;
		background: url("/img/page_13/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
	}
}
