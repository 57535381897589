.page_17{
	width: 1920px;
	height: 1080px;
	background: url("/img/page_17/bkg.jpg") center center no-repeat;
	
	position: relative;
	
	.continue {
		top: calc(843px + (175px - 128px)/2);
		left: calc(1088px + (743px - 366px)/2);
		z-index:2;
	}
	
	.popup{
		top: 843px;
		left: 1088px;
	}
	
	.popup2{
		top:449px;
		left:497px;
		z-index:100000000;
	}
	
	.figure{
		position: absolute;
		z-index:2;
		
		&.shaking{
			animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		}
	}
	
	.figure_label{
		position: absolute;
		
		transition: opacity 250ms ease-in-out;
		&.hidden{
			opacity:0;
		}
		
		&.figure_label_0{
			top: 319px;
			left: 178px;
		}
		&.figure_label_1{
			top: 494px;
			left: 169px;
		}
		&.figure_label_2{
			top: 653px;
			left: 193px;
		}
		&.figure_label_3{
			top: 817px;
			left: 220px;
		}
	}
	
	.hand_right {
		position: absolute;
    top: 561px;
    left: 472px;
	}
	
	.cta{
		position: absolute;
		
    left: 1309px;
    bottom: 721px;
		
		width:490px;
		height:155px;
		background: url("/img/page_17/cta.png") center bottom no-repeat;
		background-size: 100% auto;
		
		transition: height 150ms ease-in-out;
	}
	
	.map{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index:1;
	}
}
